import { Storage } from "aws-amplify";
import moment from "moment";
import JSZip from "jszip";

import { renderDocument } from "./render/docx";
import { renderSpreadsheet } from "./render/xlsx";
import type { ReportOptions, ReportsOptions, TemplateType } from "./types";
import { getData } from "./data";

import { downloadFile, convertDataUrl } from "utils/files";
import { GroupReport, Report } from "API";

export * from "./types";

export async function download(
  report: Report,
  projectId: string,
  groupId: string,
  options?: ReportOptions
) {
  try {
    const type = report.type;
    const dataType = report.data;

    const [data, file] = await Promise.all([
      getData(type, dataType as TemplateType, projectId, groupId, options),
      Storage.get(report.template.key, {
        download: true,
        cacheControl: "no-cache, no-store, must-revalidate",
      }),
    ]);

    const fileName = `${report.name}_${moment().format("YYYYMMDDHHmmss")}`;

    if (type === "DOCUMENT") {
      const blob = await renderDocument(file.Body as Blob, data);
      await downloadFile(fileName, blob);
    }
    if (type === "SPREADSHEET") {
      const renderData = await renderSpreadsheet(
        file.Body as Blob,
        data,
        report
      );
      await downloadFile(
        replaceFileName(renderData.customName || fileName),
        renderData.base64,
        "base64"
      );
    }
  } catch (e: any) {
    throw Error(e);
  }
}

export async function downloadMultipleReports(
  reports: Report[],
  projectId: string,
  groupId: string,
  options?: ReportsOptions
) {
  try {
    const products = options?.products;
    if (!products) return;

    let dataPromises: Promise<any>[] = [];
    let filePromises: Promise<any>[] = [];
    let fileNames: string[] = [];
    let reportList: Report[] = [];
    let dataTypes: string[] = [];

    products.forEach((product) => {
      let option = { productId: product.id };
      reports.forEach((report) => {
        const type = report.type;
        const dataType = report.data;
        dataPromises.push(
          getData(type, dataType as TemplateType, projectId, groupId, option)
        );
        filePromises.push(
          Storage.get(report.template.key, {
            download: true,
            cacheControl: "no-cache, no-store, must-revalidate",
          })
        );
        if (type === "DOCUMENT") {
          fileNames.push(
            `${product.name}_${report.name}_${moment().format(
              "YYYYMMDDHHmmss"
            )}.docx`
          );
        }
        if (type === "SPREADSHEET") {
          fileNames.push(
            `${product.name}_${report.name}_${moment().format(
              "YYYYMMDDHHmmss"
            )}.xlsx`
          );
        }
        reportList.push(report);
        dataTypes.push(dataType);
      });
    });

    const data = await Promise.all(dataPromises);
    const files = await Promise.all(filePromises);

    const blobsBase64sPromises: Promise<any>[] = [];

    data.forEach((d, idx) => {
      if (reportList[idx].type === "DOCUMENT") {
        blobsBase64sPromises.push(renderDocument(files[idx].Body as Blob, d));
      }
      if (reportList[idx].type === "SPREADSHEET") {
        blobsBase64sPromises.push(
          renderSpreadsheet(files[idx].Body as Blob, d, reportList[idx])
        );
      }
    });

    const blobsBase64s = await Promise.all(blobsBase64sPromises);
    const blobsPromises = blobsBase64s.map((b, idx) => {
      if (reportList[idx].type === "SPREADSHEET") {
        return convertDataUrl(b.base64 as string);
      }
      return b;
    });

    const blobs = await Promise.all(blobsPromises);
    const zip = new JSZip();
    fileNames.forEach((f, idx) => {
      zip.file(f, blobs[idx] as Blob);
    });
    const zipBlob = await zip.generateAsync({ type: "blob" });
    await downloadFile(`pax_${moment().format("YYYYMMDDHHmmss")}`, zipBlob);
  } catch (e: any) {
    throw Error(e);
  }
}

export async function downloadTemplate(report: Report | GroupReport) {
  try {
    const file = await Storage.get(report.template.key, {
      download: true,
      cacheControl: "no-cache, no-store, must-revalidate",
    });

    const fileName = `${report.name}_template_${moment().format(
      "YYYYMMDDHHmmss"
    )}`;

    await downloadFile(fileName, file.Body as Blob);
  } catch (e: any) {
    throw Error(e);
  }
}

/** ファイル名で使用できない文字を置換する */
export const replaceFileName = (name: string) => {
  return name.replace(/[\\./:*?"<>|]/g, "");
};
