import { API, graphqlOperation } from "aws-amplify";

import { InvoiceLine } from "API";
import { getInvoice } from "graphql/queries";
import { dateStringToJaDateTimeStr } from "utils/date";

export async function getInvoiceData(invoiceId: string, projectId: string) {
  const i = (await API.graphql(graphqlOperation(getInvoice, { id: invoiceId })))
    .data.getInvoice;

  const invoice = {
    id: i.id,
    name: i.name || "-",
    invoiceNo: i.invoiceNo || "-",
    issueDate: i.issueDate || "-",
    billingDate: i.billingDate || "-",
    recordingDate: i.recordingDate || "-",
    billingType: i.billingType || "-",
    fax: i.fax || "-",
    phone: i.phone || "-",
    taxableSubtotal: i.taxableSubtotal || "-",
    nonTaxableTotal: i.nonTaxableTotal || "-",
    subtotal: i.subtotal || "-",
    taxTotal: i.taxTotal || "-",
    taxableTotal: i.taxableTotal || "-",
    total: i.total || "-",
    description: i.description || "-",
    createdAt: i.createdAt || "-",
    updatedAt: i.updatedAt || "-",
    accountName: i.accountName || "-",
    accountSite: i.accountSite || "",
    contactName: i.contactName || "-",
    contactFirstName: i.contactFirstName || "",
    contactLastName: i.contactLastName || i.contactName || "-",
    contactTitle: i.contactTitle || "",
    contactDepartment: i.contactDepartment || "",
    accountPostalCode: i.accountAddress?.postalCode || "-",
    accountState: i.accountAddress?.state || "-",
    accountCity: i.accountAddress?.city || "-",
    accountStreet: i.accountAddress?.street || "-",
    groupName: i.groupName || "-",
    groupPostalCode: i.groupAddress?.postalCode || "-",
    groupState: i.groupAddress?.state || "-",
    groupCity: i.groupAddress?.city || "-",
    groupStreet: i.groupAddress?.street || "-",
    bankName: i.groupBank?.name || "-",
    bankBranchName: i.groupBank?.branchName || "-",
    bankBranchNumber: i.groupBank?.branchNumber || "-",
    bankAccountType: i.groupBank?.type || "-",
    bankAccountNumber: i.groupBank?.accountNumber || "-",
  };

  const ils = i.invoiceLines.items;

  const invoiceLines = ils
    .sort((a: InvoiceLine, b: InvoiceLine) => a.order - b.order)
    .map((il: InvoiceLine, idx: number) => ({
      number: idx + 1,
      order: il.order === 0 ? 0 : il.order || "-",
      name: il.name || "-",
      shippingDate: il.shippingDate
        ? dateStringToJaDateTimeStr(il.shippingDate)
        : "-",
      pricingName: il.pricingName || "-",
      quantity: il.quantity || "-",
      m3: il.m3 || "-",
      minimumAmount: il.minimumAmount || "-",
      grossWeight: il.grossWeight || "-",
      salesType: il.salesType || "-",
      amount: il.amount || "-",
      description: il.description || "-",
      unit: il.unit || "-",
      unitPrice: il.unitPrice || "-",
      taxRate: il.taxRate || "-",
      taxTotal: il.taxTotal || "-",
      taxType: il.taxType || "-",
      subtotal: il.subtotal || "-",
      total: il.total || "-",
      createdAt: il.createdAt ? dateStringToJaDateTimeStr(il.createdAt) : "-",
      updatedAt: il.updatedAt ? dateStringToJaDateTimeStr(il.updatedAt) : "-",
    }));

  const data = {
    invoice,
    invoiceLines,
  };

  return data;
}
