import { API, graphqlOperation } from "aws-amplify";

import { Schedule } from "API";
import { getProject } from "graphql/queries";
import { getProjectTypeName } from "utils/project";

export async function getProjectData(projectId: string) {
  const p = (await API.graphql(graphqlOperation(getProject, { id: projectId })))
    .data.getProject;

  const project = {
    "project.id": p.id,
    "project.name": p.name || "-",
    "project.accountName": p.accountName || "-",
    "project.contactName": p.contactName || "-",
    "project.contactTitle": p.contactTitle || "-",
    "project.reference": p.reference || "-",
    "project.alert": p.alert || "",
    "project.qrcode": "qrcode",
    "project.userName": p.user.name || "-",
    "project.managerName": p.managerName || "-",
    "project.quoterName": p.quoterName || "-",
    "project.operatorName": p.operatorName || "-",
    "project.type": getProjectTypeName(p.type),
  };

  const ss = p.schedules.items;

  const schedules = ss.map((s: Schedule, idx: number) => ({
    number: idx + 1,
    id: s.id,
    name: s.name || "-",
    m3: s.m3 || "-",
    case: s.case || "-",
    shipType: s.shipType || "-",
    stockingDate: s.stockingDate || "-",
    packagingDate: s.packagingDate || "-",
    shippingDate: s.shippingDate || "-",
    cutDate: s.cutDate || "-",
  }));

  const g = p.group;

  const group = {
    "group.name": g.name || "-",
    "group.phone": g.phone || "-",
    "group.fax": g.fax || "-",
    "group.email": g.email || "-",
    "group.taxRate": g.taxRate || "-",
  };

  const ga = p.group.groupAddress;

  const groupAddress = {
    "groupAddress.postalCode": ga.postalCode || "-",
    "groupAddress.country": ga.country || "-",
    "groupAddress.state": ga.state || "-",
    "groupAddress.city": ga.city || "-",
    "groupAddress.street": ga.street || "-",
  };

  const gb = p.group.bank;

  const groupBank = {
    "groupBank.name": gb.name || "-",
    "groupBank.branchName": gb.branchName || "-",
    "groupBank.branchNumber": gb.branchNumber || "-",
    "groupBank.type": gb.type || "-",
    "groupBank.accountNumber": gb.accountNumber || "-",
  };

  const account = {
    "account.name": p.accountName || "-",
    "account.contactName": p.contactName || "-",
    "account.contactTitle": p.contactTitle || "-",
    "account.billingPostalCode": p.account.billingPostalCode || "-",
    "account.billingState": p.account.billingState || "-",
    "account.billingStreet": p.account.billingStreet || "-",
  };

  const data = {
    ...project,
    schedules,
    ...group,
    ...groupAddress,
    ...groupBank,
    ...account,
  };

  return data;
}
