import { API, graphqlOperation } from "aws-amplify";

import { Schedule } from "API";
import { getProject } from "graphql/queries";
import { getProjectTypeName } from "utils/project";

export async function getProjectData(projectId: string) {
  const p = (await API.graphql(graphqlOperation(getProject, { id: projectId })))
    .data.getProject;

  const project = {
    id: p.id,
    name: p.name || "-",
    type: getProjectTypeName(p.type),
    accountName: p.accountName || "-",
    contactName: p.contactName || "-",
    contactTitle: p.contactTitle || "-",
    reference: p.reference || "-",
    alert: p.alert || "",
    qrcode: "qrcode",
    userName: p.user.name || "-",
    managerName: p.managerName || "-",
    quoterName: p.quoterName || "-",
    operatorName: p.operatorName || "-",
  };

  const ss = p.schedules.items;

  const schedules = ss.map((s: Schedule, idx: number) => ({
    number: idx + 1,
    id: s.id,
    name: s.name || "-",
    m3: s.m3 || "-",
    case: s.case || "-",
    shipType: s.shipType || "-",
    stockingDate: s.stockingDate || "-",
    packagingDate: s.packagingDate || "-",
    shippingDate: s.shippingDate || "-",
    cutDate: s.cutDate || "-",
  }));

  const g = p.group || {};

  const group = {
    name: g.name || "-",
    phone: g.phone || "-",
    fax: g.fax || "-",
    email: g.email || "-",
    taxRate: g.taxRate || "-",
  };

  const ga = p.group.groupAddress || {};

  const groupAddress = {
    postalCode: ga.postalCode || "-",
    country: ga.country || "-",
    state: ga.state || "-",
    city: ga.city || "-",
    street: ga.street || "-",
  };

  const gb = p.group.bank || {};

  const groupBank = {
    name: gb.name || "-",
    branchName: gb.branchName || "-",
    branchNumber: gb.branchNumber || "-",
    type: gb.type || "-",
    accountNumber: gb.accountNumber || "-",
  };

  const account = {
    name: p.accountName || "-",
    contactName: p.contactName || "-",
    contactTitle: p.contactTitle || "-",
    billingPostalCode: p.account.billingPostalCode || "-",
    billingState: p.account.billingState || "-",
    billingStreet: p.account.billingStreet || "-",
  };

  const data = {
    project,
    schedules,
    group,
    groupAddress,
    groupBank,
    account,
  };

  return data;
}
