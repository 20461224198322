import { API, graphqlOperation } from "aws-amplify";

import { InvoiceLine } from "API";
import { invoicesByProjectId } from "graphql/queries";
import { dateStringToJaDateTimeStr } from "utils/date";

export async function getInvoiceData(projectId: string) {
  const i = (
    await API.graphql(graphqlOperation(invoicesByProjectId, { projectId }))
  ).data.invoicesByProjectId.items[0];

  const invoice = {
    "invoice.id": i.id,
    "invoice.name": i.name || "-",
    "invoice.invoiceNo": i.invoiceNo || "-",
    "invoice.issueDate": i.issueDate || "-",
    "invoice.billingDate": i.billingDate || "-",
    "invoice.recordingDate": i.recordingDate || "-",
    "invoice.billingType": i.billingType || "-",
    "invoice.fax": i.fax || "-",
    "invoice.phone": i.phone || "-",
    "invoice.taxed": i.taxed || "-",
    "invoice.untaxed": i.untaxed || "-",
    "invoice.subtotal": i.subtotal || "-",
    "invoice.taxTotal": i.taxTotal || "-",
    "invoice.taxableTotal": i.taxableTotal || "-",
    "invoice.total": i.total || "-",
    "invoice.description": i.description || "-",
    "invoice.createdAt": i.createdAt || "-",
    "invoice.updatedAt": i.updatedAt || "-",
    "invoice.accountName": i.accountName || "-",
    "invoice.contactName": i.contactName || "-",
    "invoice.contactTitle": i.contactTitle || "-",
    "invoice.accountPostalCode": i.accountAddress.postalCode || "-",
    "invoice.accountState": i.accountAddress.state || "-",
    "invoice.accountCity": i.accountAddress.city || "-",
    "invoice.accountStreet": i.accountAddress.street || "-",
    "invoice.groupName": i.groupName || "-",
    "invoice.groupPostalCode": i.groupAddress.postalCode || "-",
    "invoice.groupState": i.groupAddress.state || "-",
    "invoice.groupCity": i.groupAddress.city || "-",
    "invoice.groupStreet": i.groupAddress.street || "-",
    "invoice.bankName": i.groupBank.name || "-",
    "invoice.bankBranchName": i.groupBank.branchName || "-",
    "invoice.bankBranchNumber": i.groupBank.branchNumber || "-",
    "invoice.bankAccountType": i.groupBank.type || "-",
    "invoice.bankAccountNumber": i.groupBank.accountNumber || "-",
  };

  const ils = i.invoiceLines.items;

  const invoiceLines = ils.map((il: InvoiceLine, idx: number) => ({
    number: idx + 1,
    order: il.order + 1 || "-",
    name: il.name || "-",
    shippingDate: il.shippingDate
      ? dateStringToJaDateTimeStr(il.shippingDate)
      : "-",
    pricingName: il.pricingName || "-",
    quantity: il.quantity || "-",
    m3: il.m3 || "-",
    minimumAmount: il.minimumAmount || "-",
    grossWeight: il.grossWeight || "-",
    amount: il.amount || "-",
    description: il.description || "-",
    unit: il.unit || "-",
    unitPrice: il.unitPrice || "-",
    taxRate: il.taxRate || "-",
    taxTotal: il.taxTotal || "-",
    taxType: il.taxType || "-",
    subtotal: il.subtotal || "-",
    total: il.total || "-",
    createdAt: il.createdAt ? dateStringToJaDateTimeStr(il.createdAt) : "-",
    updatedAt: il.updatedAt ? dateStringToJaDateTimeStr(il.updatedAt) : "-",
  }));

  const data = {
    invoice,
    invoiceLines,
  };

  return data;
}
