import { API, graphqlOperation } from "aws-amplify";

import { productSummariesByProjectId } from "graphql/queries";
import { ProductSummariesFilter } from "utils/productSummary";

export async function getProductSummaryData(
  projectId: string,
  filter: ProductSummariesFilter | undefined,
  lineOrderMapping: { [key: string]: number } | undefined = undefined
) {
  const p = (
    await API.graphql(
      graphqlOperation(productSummariesByProjectId, {
        input: { projectId, filter },
      })
    )
  ).data.productSummariesByProjectId.items;

  if (lineOrderMapping === undefined) {
    p.sort(
      // ここのsortの部分を持ってきた順番の物を使うようにする
      (a: any, b: any) => a.productOrder - b.productOrder
    );
  } else {
    p.map((productSummary: any) => {
      productSummary.number =
        lineOrderMapping[`${productSummary.productId}|${productSummary.order}`];
      return productSummary;
    });
    p.sort((a: any, b: any) => a.number - b.number);
  }

  const summaryLines = p.map((s: any, idx: number) => {
    return {
      number: p.number ?? idx + 1,
      ...s,
      phaseName: s.phaseName === "complete" ? "完了" : s.phaseName,
    };
  });

  const summary = p.reduce(
    (r: any, s: any) => {
      r.totalQuantity += s.quantity || 0;
      r.totalNetWeight += s.netWeight || 0;
      r.totalGrossWeight += s.grossWeight || 0;
      r.totalM3 += s.m3 || 0;

      return r;
    },
    {
      totalQuantity: 0,
      totalNetWeight: 0,
      totalGrossWeight: 0,
      totalM3: 0,
    }
  );

  const data = {
    summary,
    summaryLines,
  };

  return data;
}
